<template>
    <v-dialog v-model="dialog" width="800" @keydown.esc="cancel" @click:outside="cancel">
        <router-view @saved="$emit('saved', $event)" name="modalContent"/>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogForm",
    data () {
        return {
            dialog: true
        }
    },
    methods: {
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>

